import { Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import mentoringImg from '../../../assets/misc_photos/mentoring.jpg';

export default function Faq() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        width: "100vw",
        justifyContent: "center",
        px: 2,
        py: 4,
      }}
    >
      <Box
        sx={{
          width: { xs: "100%", sm: "1100px" },
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: { xs: "100%", sm: "50%" },
            color: "#3B3B3B",
            mb: { xs: 2, sm: 0 },
          }}
        >
          <Box
            sx={{
              fontWeight: 500,
              fontSize: "12px",
              textTransform: "uppercase",
              mb: "8px",
            }}
          >
            Frequently Asked Questions
          </Box>
          <Accordion>
            <AccordionSummary>
              <Box sx={{ color: theme.palette.primary.main }}>
                Where is Advance Careers based and who do we serve?
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              Advance Careers is based in Australia and is dedicated to serving the needs of Australian youth as they transition from high school to higher education and career opportunities.
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary>
              <Box sx={{ color: theme.palette.primary.main }}>
                How do I get involved with Advance Careers?
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              To get involved, you can sign up on our website, attend our informative workshops, or contact us directly to learn more about volunteer and partnership opportunities.
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary>
              <Box sx={{ color: theme.palette.primary.main }}>
                What makes our workshops unique?
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              Our workshops are uniquely led by university students who have recently experienced the transition from high school. This ensures that the advice and guidance provided is both relatable and practical, directly addressing the challenges faced by high school students.
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box
          sx={{
            width: { xs: "100%", sm: "32px" },
            height: { xs: "16px", sm: "auto" },
          }}
        />
        <Box
          sx={{
            width: { xs: "100%", sm: "50%" },
            mb: { xs: 2, sm: 0 },
          }}
        >
          <img
            width="100%"
            src={mentoringImg}
            style={{ borderRadius: "24px" }}
            alt="Mentoring session"
          />
        </Box>
      </Box>
    </Box>
  );
}

