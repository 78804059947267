import React, { useState } from 'react';
import { Box, Typography, Button, IconButton } from '@mui/material';
import { motion } from 'framer-motion';
import { FaBolt } from "react-icons/fa";
import BaulkhamImg from '../../assets/programs/baulkham_career_panel/12.jpg';
import SlideShow from './SlideShow';
// Canterbury Photos
import Canterbury1 from '../../assets/programs/Canterbury Boys HS Workshop Photos/Stage 5 Careers event/IMG_6494.jpg'
import Canterbury2 from '../../assets/programs/Canterbury Boys HS Workshop Photos/Stage 5 Careers event/IMG_6497.jpg'
import Canterbury3 from '../../assets/programs/Canterbury Boys HS Workshop Photos/Yr 11 Advance Careers Panel/20241023_113632.jpg'
import Canterbury4 from '../../assets/programs/Canterbury Boys HS Workshop Photos/Yr 11 Advance Careers Study Skills/20240904_121604 (1).jpg'
import Canterbury5 from '../../assets/programs/Canterbury Boys HS Workshop Photos/Stage 5 Careers event/IMG_8434.jpg'
import Canterbury6 from '../../assets/programs/Canterbury Boys HS Workshop Photos/Yr 11 Advance Careers Panel/20241023_121022.jpg'
// Baulkham Photos
import Baulkham1 from '../../assets/programs/baulkham_career_panel/1.jpg'
import Baulkham2 from '../../assets/programs/baulkham_career_panel/2.jpg'
import Baulkham3 from '../../assets/programs/baulkham_career_panel/3.jpg'
import Baulkham4 from '../../assets/programs/baulkham_career_panel/4.jpg'
import Baulkham5 from '../../assets/programs/baulkham_career_panel/5.jpg'
import Baulkham6 from '../../assets/programs/baulkham_career_panel/11.jpg'
import Baulkham7 from '../../assets/programs/baulkham_career_panel/12.jpg'
import Baulkham8 from '../../assets/programs/baulkham_career_panel/preview.jpg'
// Homebush Photos
import Homebush1 from '../../assets/programs/homebush_subject_expert/6.jpg'
import Homebush2 from '../../assets/programs/homebush_subject_expert/7.jpg'
import Homebush3 from '../../assets/programs/homebush_subject_expert/8.jpg'
// Parramatta Photos
import Parra1 from '../../assets/programs/paramatta_study_skills/9.jpg'
import Parra2 from '../../assets/programs/paramatta_study_skills/10.jpg'
import Parra3 from '../../assets/programs/paramatta_study_skills/preview.jpg'
// Misc Photos
import Misc1 from '../../assets/unsw_shoot/Presenting2.jpg'


// Exemplar Object
// {
//   date: 'September 30, 2023',
//   title: 'New Payment Gateway Options',
//   description: `Your customers' convenience is a priority. We've expanded your options
//                 with new payment gateways, making transactions easier and more flexible.`,
//   images: '/assets/ui-revamp.png',
// }

const timelineItems = [
  {
    date: 'October 15, 2024',
    title: '2,000 Students and Growing!',
    description: `Our impact continues to grow! Workshops at Canterbury Boys High School, Willoughby Girls High School, and Girraween High School take us past 2,000 students.`,
    images: [
      Canterbury1,
      Canterbury2,
      Canterbury3,
      Canterbury4,
      Canterbury5,
      Canterbury6,
    ],
  },
  {
    date: 'August 5, 2024',
    title: '1,000 Students Inspired!',
    description: `We’ve doubled our reach! Hunters Hill High School and Baulkham Hills High School join our initiative, empowering even more students.`,
    images: [
      Baulkham1,
      Baulkham2,
      Baulkham3,
      Baulkham4,
      Baulkham5,
      Baulkham6,
      Baulkham7,
      Baulkham8,
    ],
  },
  {
    date: 'June 21, 2024',
    title: '500 Students Reached!',
    description: `Workshops conducted at Bonnyrigg High School, Homebush Boys High School, and Sydney Technical High School—expanding our impact across Sydney!`,
    images: [
      Homebush1,
      Homebush2,
      Homebush3,
    ]
  },
  {
    date: 'December 13, 2023',
    title: 'First Advance Careers Workshop',
    description: `Our first-ever workshop at Parramatta High School! A great start to inspiring students with career guidance and valuable skills for their future.`,
    images: [
      Parra1,
      Parra2,
      Parra3,
    ]
  },
  {
    date: 'December 1, 2022',
    title: 'Advance Careers Becomes a Registered Charity',
    description: `A milestone moment! Advance Careers is officially recognized as a registered charity, marking the beginning of our journey to empower students.`,
    imageUrl1: Misc1,
  },
];

const ITEMS_PER_PAGE = 5;
let isLastItem;

const ProgressTimeline = () => {
  const [visibleCount, setVisibleCount] = useState(ITEMS_PER_PAGE);

  const handleLoadMore = () => {
    setVisibleCount((prevCount) => prevCount + ITEMS_PER_PAGE);
  };

  const handleShowLess = () => {
    setVisibleCount(ITEMS_PER_PAGE);
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '100px' }}>
      <Box sx={{ position: 'relative', py: 4, width: '1000px' }}>
        {timelineItems.slice(0, visibleCount).map((item, index, visibleItems) => {

          isLastItem = index === visibleCount - 1;
          const images = item.images || []
          console.log("ITEM: ", item)
          console.log("IMAGES: ", images)

          return (
            <Box
              key={index}
              component={motion.div}
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              sx={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                paddingBottom: '50px',
              }}
            >
              {/* Vertical line for each item */}
              <Box
                component={motion.div}
                initial={{ scaleY: 0 }}
                whileInView={{ scaleY: 1 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                sx={{
                  position: 'absolute',
                  left: '30px',
                  top: 0,
                  bottom: 0,
                  width: '2px',
                  backgroundColor: '#E0E0E0',
                  transformOrigin: 'top',
                }}
              />

              {/* Main circle at the top */}
              <Box
                initial={{ opacity: 0 }}
                animate={{ opacity: [0, 1, 0] }}
                sx={{
                  position: 'absolute',
                  left: '10px',
                  top: '0',
                  width: '40px',
                  height: '40px',
                  borderRadius: '50%',
                  backgroundImage:
                    'linear-gradient(to right, #7474BF 0%, #348AC7 51%, #7474BF 100%)',
                  transition: 'all ease-in 200ms',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <FaBolt size={16} color="white" />
              </Box>


              {/* Render the date and content only if this is not the last item */}
              <Box
                sx={{
                  width: '250px',
                  height: '100%',
                  marginTop: '10px',
                }}
              >
                <Box sx={{ marginLeft: '80px' }}>
                  <Typography variant="body2" sx={{ mb: 1, color: 'text.secondary' }}>
                    {item.date}
                  </Typography>
                </Box>
              </Box>

              {/* Render the Content */}
              <Box
                sx={{
                  display: 'flex',
                  border: '1px solid #E0E0E0',
                  borderRadius: '20px',
                  width: '750px',
                  padding: '40px 30px',
                }}
              >
                {/* TOP STATIC IMAGE */}
                <Box>
                  {item.imageUrl1 && (
                    <Box
                      component="img"
                      src={item.imageUrl1}
                      alt={item.title}
                      sx={{
                        mt: 2,
                        maxWidth: '100%',
                        borderRadius: 2,
                        boxShadow: 1,
                        marginBottom: '30px',
                      }}
                    />
                  )}
                  {/* TITLE */}
                  <Box
                    sx={{
                      paddingBottom: '30px',
                      fontWeight: 600,
                      fontSize: '20px',
                      lineHeight: '100%',
                    }}
                  >
                    {item.title}
                  </Box>
                  {/* DESCRIPTION */}
                  <Box sx={{ color: '#555', fontSize: '14px', paddingBottom: '20px' }}>
                    {item.description}
                  </Box>
                   
                  {/* SLIDE SHOW */}
                  <Box sx={{ marginBottom: '-25px'}}>
                    {images.length > 0 && (
                      <SlideShow images={images} alt={item.title} />
                    )}
                  </Box>
                </Box>
              </Box>

              {/* For the 5th item, render an additional circle at the bottom */}
              {isLastItem && (
                <Box
                  initial={{ opacity: 0 }}
                  animate={{ opacity: [0, 1, 0] }}
                  sx={{
                    position: 'absolute',
                    left: '10px',
                    bottom: '0',
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    backgroundImage:
                      'linear-gradient(to right, #7474BF 0%, #348AC7 51%, #7474BF 100%)',
                    transition: 'all ease-in 200ms',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <FaBolt size={16} color="white" />
                </Box>
              )}
            </Box>
          );
        })}

        {/* For the 5th item, render Load More Updates button if more items left */}
        {isLastItem && visibleCount < timelineItems.length && (
          <Box sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}>
            <Button
              variant="outlined"
              onClick={handleLoadMore}
              sx={{
                padding: '10px 20px',
                borderRadius: '50px',
                border: '2px solid #2C3E50',
                color: '#2C3E50',
                fontSize: '16px',
                width: '750px',
                backgroundColor: 'transparent',
                transition: 'all 0.3s ease-in-out',
                '&:hover': {
                  backgroundColor: '#2C3E50',
                  color: 'white',
                },
              }}
            >
              Load More Updates
            </Button>
          </Box>
        )}
      </Box>

      {/* {visibleCount > ITEMS_PER_PAGE && ( */}
      {/*   <Button */}
      {/*     variant="outlined" */}
      {/*     onClick={handleShowLess} */}
      {/*     sx={{ marginTop: '10px', padding: '10px 20px', borderRadius: '20px' }} */}
      {/*   > */}
      {/*     Show Less */}
      {/*   </Button> */}
      {/* )} */}

    </Box>
  );
};
export default ProgressTimeline;

