import { Box } from '@mui/material';
import { motion } from 'framer-motion';

export default ({ sx = {}, children, clickHandler, boxShadow }) => {
  const defaultSx = {
    backgroundImage: 'linear-gradient(to right, #7474BF 0%, #348AC7 51%, #7474BF 100%)',
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: 'center',
    padding: "10px 36px",
    transition: '0.5s',
    backgroundSize: '200% auto',
    color: 'white',
    boxShadow: boxShadow
      ? "#5A7EC390 0px 7px 29px 0px"
      : "#5A7EC340 0px 7px 29px 0px",
    borderRadius: '16px',
    fontWeight: 500,
    cursor: "pointer",
    "&:hover": {
      backgroundPosition: 'right center',
      color: '#fff',
      textDecoration: 'none',
    },
  };

  return (
    <motion.div
      whileHover={{ scale: 1.025 }}		
      whileTap={{ scale: 0.995 }}
      onClick={clickHandler}
    >
      <Box sx={{ ...defaultSx, ...sx }}>
        {children}
      </Box>
    </motion.div>
  );
};

