import { Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export default () => {

	const theme = useTheme();

	return (
		<>
		<Accordion sx={{
			boxShadow: "rgba(149, 157, 165, 0.2) 0px 1px 6px",
			marginBottom: "24px"
		}}>
			<AccordionSummary>
				<Box sx={{ 
					color: theme.palette.primary.main,
					fill: theme.palette.primary.main,
					fontWeight: 550,
					display: "flex",
					gap: "8px"
				}}>
					{/*<HeartIcon width="18px" />*/}
				Where do you run workshops?
			</Box>
			</AccordionSummary>
			<AccordionDetails>
				NSW only for now, but we are definitely open to going interstate in reaching a greater youth audience!
			</AccordionDetails>
		</Accordion>
		<Accordion sx={{
			boxShadow: "rgba(149, 157, 165, 0.2) 0px 1px 6px",
			marginBottom: "24px"
		}}>
			<AccordionSummary>
				<Box sx={{ 
					color: theme.palette.primary.main,
					fill: theme.palette.primary.main,
					fontWeight: 550,
					display: "flex",
					gap: "8px"
				}}>
					{/*<HeartIcon width="18px" />*/}
				Where do you run workshops?
			</Box>
			</AccordionSummary>
			<AccordionDetails>
				NSW only for now, but we are definitely open to going interstate in reaching a greater youth audience!
			</AccordionDetails>
		</Accordion>
		<Accordion sx={{
			boxShadow: "rgba(149, 157, 165, 0.2) 0px 1px 6px",
			marginBottom: "24px"
		}}>
			<AccordionSummary>
				<Box sx={{ 
					color: theme.palette.primary.main,
					fill: theme.palette.primary.main,
					fontWeight: 550,
					display: "flex",
					gap: "8px"
				}}>
					{/*<HeartIcon width="18px" />*/}
				Where do you run workshops?
			</Box>
			</AccordionSummary>
			<AccordionDetails>
				NSW only for now, but we are definitely open to going interstate in reaching a greater youth audience!
			</AccordionDetails>
		</Accordion>
		</>
	)
}
