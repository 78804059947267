import { Box } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import GradientButton from '../GradientButton';
import TextButton from '../TextButton';
import { ReactComponent as LogoIcon } from '../../../assets/sidebar_icons/logo.svg';

export default () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        zIndex: 10,
        width: "100%",
        backgroundColor: "white",
        boxShadow: "rgb(0 0 0 / 6%) 0px 1px 5px 0px",
        color: "#3B3B3B",
        pt: { xs: "18px", sm: 0 },
        pb: { xs: "12px", sm: 0 },
      }}
    >
      {/* Inner container to limit max width on desktop */}
      <Box
        sx={{
          width: { xs: "100%", sm: "1125px" },
          mx: "auto",
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          alignItems: "center",
          justifyContent: "space-between",
          px: { xs: "20px", sm: "36px" },
          height: { xs: "auto", sm: "86px" },
        }}
      >
        {/* Brand/Logo */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            fontWeight: 600,
            fontSize: { xs: "16px", sm: "18px" },
            gap: "10px",
            textAlign: { xs: "center", sm: "left" },
            mb: { xs: "10px", sm: 0 },
          }}
        >
          <Box
            sx={{
              width: { xs: "30px", sm: "40px" },
              height: { xs: "30px", sm: "40px" },
            }}
          >
            <LogoIcon width="100%" height="100%" />
          </Box>
          Advance Careers
        </Box>

        {/* Navigation Links */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            transform: { xs: "none", sm: "translateY(6px)" },
            fontWeight: 500,
            gap: { xs: "12px", sm: "24px" },
            cursor: "pointer",
            textAlign: { xs: "center", sm: "left" },
            mb: { xs: "10px", sm: 0 },
          }}
        >
          <TextButton
            sx={{ fontSize: { xs: "14px", sm: "16px" } }}
            onClick={() => navigate("/")}
            selected={location.pathname === "/"}
          >
            Home
          </TextButton>
          <TextButton
            sx={{ fontSize: { xs: "14px", sm: "16px" } }}
            onClick={() => navigate("/about")}
            selected={location.pathname === "/about"}
          >
            About
          </TextButton>
          <TextButton
            sx={{ fontSize: { xs: "14px", sm: "16px" } }}
            onClick={() => navigate("/programs")}
            selected={location.pathname === "/programs"}
          >
            Our Programs
          </TextButton>
          <TextButton
            sx={{ fontSize: { xs: "14px", sm: "16px" } }}
            onClick={() => navigate("/contact")}
            selected={location.pathname === "/contact"}
          >
            Contact Us
          </TextButton>
        </Box>

        {/* Call-to-Action Button */}
        <Box sx={{ mb: { xs: "6px", sm: 0 } }}>
          <GradientButton
            sx={{
              height: { xs: "30px", sm: "42px" },
              padding: { xs: "0 14px", sm: "0 36px" },
              fontSize: { xs: "12px", sm: "16px" },
              borderRadius: { xs: "12px", sm: "16px" },
            }}
            clickHandler={() =>
              window.open("https://forms.gle/rk1C5VFGnZanoQWH8", "_blank")
            }
          >
            Join Us!
          </GradientButton>
        </Box>
      </Box>
    </Box>
  );
};

