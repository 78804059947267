import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactComponent as FacebookIcon } from '../assets/socialmedia_img/facebook.svg';
import { ReactComponent as InstagramIcon } from '../assets/socialmedia_img/instagram.svg';
import { ReactComponent as LinkedInIcon } from '../assets/socialmedia_img/linkedin.svg';

export default () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "100%",
        borderTop: "1px solid #E8E8E8",
        backgroundColor: "white",
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        alignItems: "center",
        justifyContent: "space-between",
        padding: { xs: "16px", md: "0 64px" },
        gap: { xs: "16px", md: 0 },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          alignItems: "center",
          gap: "10px",
          textAlign: { xs: "center", md: "left" },
        }}
      >
        <Box sx={{ fontSize: { xs: "14px", md: "inherit" } }}>
          © {new Date().getFullYear()} Advance Australia Careers Limited
        </Box>
        <Box sx={{ fontSize: "10px", transform: "translateY(1.5px)" }}>
          Site developed by Ethan Han &amp; Adrian Si
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "20px",
          fill: theme.palette.primary.main,
          "& svg": { width: { xs: "16px", md: "18px" } },
        }}
      >
        <FacebookIcon />
        <InstagramIcon />
        <LinkedInIcon />
      </Box>
    </Box>
  );
};

