import { useState } from 'react';
import { motion } from 'framer-motion';
import { Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CountUp from 'react-countup';
import Carousel from '../../../global_components/Animated/CardCarousel/Carousel';
import Card from '../../../global_components/Animated/CardCarousel/Card';
import climbImg from '../../assets/stats_img/climb.png';
import australiaImg from '../../assets/stats_img/australia.png';
import usersImg from '../../assets/stats_img/users.png';
import bookImg from '../../assets/stats_img/book.png';
import bonnyriggLogo from '../../assets/school_logos/bonnrigg.png';
import homebushboysLogo from '../../assets/school_logos/homebushboys.png';
import sydneytechLogo from '../../assets/school_logos/sydneytech.jpeg';
import huntershillLogo from '../../assets/school_logos/huntershill.jpg';
import paramattaLogo from '../../assets/school_logos/paramatta.jpeg';
import soosieImg from '../../assets/school_logos/soosie.png';
import sampleImg from '../../assets/school_logos/sample.png';
import testLogo from '../../../assets/test.png';
import bg1Img from '../../../assets/bg_img/1.png';

const SchoolCard = ({ schoolLogoImg, schoolName, description, url }) => {
  return (
    <Box 
      onClick={() => window.open(url, '_blank')}
      sx={{
        boxShadow: "rgba(149, 157, 165, 0.15) 0px 1px 12px",
        borderRadius: "6px",
        // Increase overall card height on mobile to allow a larger logo
        height: { xs: "120px", sm: "100px" },
        // Mobile: full width (minus horizontal margins); desktop: auto
        width: { xs: "calc(100% - 36px)", sm: "auto" },
        // On mobile, reduce vertical spacing
        margin: { xs: "6px auto", sm: 0 },
        backgroundColor: "white",
        display: "flex",
        padding: "6px",
        cursor: "pointer"
      }}
    >
      {/* Logo Container */}
      <Box 
        sx={{
          // On mobile, fixed size; on desktop, use full card height (100px) with a 1:1 aspect ratio.
          height: { xs: "80px", sm: "100%" },
          width: { xs: "80px", sm: "auto" },
          aspectRatio: { sm: "1" },
          padding: { xs: "8px", sm: "12px" }
        }}
      >
        <Box 
          sx={{
            height: "100%",
            width: "100%",
            backgroundImage: `url(${schoolLogoImg})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        />
      </Box>
      {/* Text Details */}
      <Box 
        sx={{
          paddingRight: "34px",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <Box sx={{ fontSize: "14px", fontWeight: 500 }}>
          {schoolName}
        </Box>
        <Box 
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: "11px",
            fontWeight: 400,
            fontStyle: "italic"
          }}
        >
          {description}
        </Box>
      </Box>
    </Box>
  );
};

const IconContainer = ({ imgSrc, delay, children }) => (
  <motion.div 
    style={{
      display: "flex", 
      flexDirection: "column",
      alignItems: "center",
      gap: "12px",
      width: "200px",
      lineHeight: "120%",
      fontSize: "14px",
      fontWeight: 500,
      textAlign: "center"
    }}
    initial={{ scale: 0.9, opacity: 0 }}
    whileInView={{ scale: 1, opacity: 1 }}
    transition={{ 
      opacity: { delay, type: "spring", stiffness: 200, damping: 20 },
      scale: { delay, type: "spring", stiffness: 300, damping: 12 }
    }}
  >
    <img src={imgSrc} height="46px" alt="icon" />
    {children}
  </motion.div>
);

export default function ImpactSection() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Box sx={{
      display: "flex",
      justifyContent: "center",
      overflow: "hidden",
      backgroundImage: `url(${bg1Img})`,
      backgroundPosition: "0 125%",
    }}>
      <Box sx={{ width: { xs: "100%", sm: "1120px" }, position: "relative" }}>
        <motion.div 
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            color: "#3B3B3B",
            marginTop: "64px"
          }}
          initial={{ y: 36, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ type: "spring", stiffness: 140, damping: 12 }}
        >
          {/* Section Titles */}
          <Box sx={{ fontWeight: 600, color: theme.palette.primary.main, textAlign: "center" }}>
            Our Impact
          </Box>
          <Box sx={{ 
            fontWeight: 700, 
            fontSize: { xs: "28px", sm: "36px" },
            textAlign: "center",
            mt: "8px"
          }}>
            What We've Done
          </Box>
          <Box sx={{ 
            textAlign: "center", 
            mt: "15px", 
            fontSize: { xs: "14px", sm: "16px" },
            width: { xs: "90%", sm: "80%" } 
          }}>
            From workshops delivered to careers launched, see how Advance Careers is making a measurable difference in the lives of young Australians. Join us in celebrating our achievements and learn how you can help drive further success.
          </Box>
          <Box sx={{ 
            mt: "36px", 
            fontWeight: 500, 
            textAlign: "center",
            fontSize: { xs: "14px", sm: "16px" },
          }}>
            We've worked with the following schools and we're constantly growing!
          </Box>

          {/* School Cards Section */}
          <motion.div 
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 0.2, type: "spring", stiffness: 140, damping: 12 }}
            style={{
              maxWidth: "100%",
              backgroundColor: "#F3F7FF",
              borderRadius: "16px",
              padding: "16px 12px",
              marginTop: "24px"
            }}
          >
            {isDesktop ? (
              <Box sx={{
                maxWidth: "100%",
                display: "flex",
                overflowX: "scroll",
                whiteSpace: "nowrap",
                borderRadius: "16px",
                p: "8px",
                gap: "18px"
              }}>
                <SchoolCard
                  schoolLogoImg={bonnyriggLogo}
                  schoolName="Bonnyrigg High School"
                  description="Bonnyrigg, New South Wales"
                  url="https://bonnyrigg-h.schools.nsw.gov.au/"
                />
                <SchoolCard
                  schoolLogoImg={homebushboysLogo}
                  schoolName="Homebush Boys High School"
                  description="Homebush, New South Wales"
                  url="https://homebushbo-h.schools.nsw.gov.au/"
                />
                <SchoolCard
                  schoolLogoImg={sydneytechLogo}
                  schoolName="Sydney Technical High School"
                  description="Bexley, New South Wales"
                  url="https://sydneytech-h.schools.nsw.gov.au/"
                />
                <SchoolCard
                  schoolLogoImg={huntershillLogo}
                  schoolName="Hunters Hill High School"
                  description="Hunters Hill, New South Wales"
                  url="https://huntershd-h.schools.nsw.gov.au/"
                />
                <SchoolCard
                  schoolLogoImg={paramattaLogo}
                  schoolName="Parramatta High School"
                  description="Parramatta, New South Wales"
                  url="https://parramatta-h.schools.nsw.gov.au/"
                />
              </Box>
            ) : (
              <Box sx={{
                maxWidth: "100%",
                height: "320px",
                overflowY: "scroll",
                display: "flex",
                flexDirection: "column",
                gap: "4px",
                borderRadius: "16px",
                p: "8px"
              }}>
                <SchoolCard
                  schoolLogoImg={bonnyriggLogo}
                  schoolName="Bonnyrigg High School"
                  description="Bonnyrigg, New South Wales"
                  url="https://bonnyrigg-h.schools.nsw.gov.au/"
                />
                <SchoolCard
                  schoolLogoImg={homebushboysLogo}
                  schoolName="Homebush Boys High School"
                  description="Homebush, New South Wales"
                  url="https://homebushbo-h.schools.nsw.gov.au/"
                />
                <SchoolCard
                  schoolLogoImg={sydneytechLogo}
                  schoolName="Sydney Technical High School"
                  description="Bexley, New South Wales"
                  url="https://sydneytech-h.schools.nsw.gov.au/"
                />
                <SchoolCard
                  schoolLogoImg={huntershillLogo}
                  schoolName="Hunters Hill High School"
                  description="Hunters Hill, New South Wales"
                  url="https://huntershd-h.schools.nsw.gov.au/"
                />
                <SchoolCard
                  schoolLogoImg={paramattaLogo}
                  schoolName="Parramatta High School"
                  description="Parramatta, New South Wales"
                  url="https://parramatta-h.schools.nsw.gov.au/"
                />
              </Box>
            )}
          </motion.div>

          {/* Testimonial Section (desktop only) */}
          {isDesktop && (
            <Box sx={{
              display: "flex",
              gap: "36px",
              width: "860px",
              alignItems: "center",
              mt: "64px"
            }}>
              <Box sx={{ p: "0 36px" }}>
                <Carousel sx={{
                  width: "300px",
                  height: "360px",
                }}>
                  <Card
                    key={0}
                    name="Year 10 Student"
                    iconImg={testLogo}
                    from="from Parramatta High School"
                    content="Advance Careers are willing to answer almost any of your questions with proper and helpful answers which I recommend for lots of students!"
                    heading="Proper and helpful answers"
                  />
                  <Card
                    key={2}
                    name="Michelle I."
                    iconImg={sampleImg}
                    from="Careers Advisor from Parramatta High School"
                    content="The workshops were fabulous today, I was very excited to see 12 fantastic university students coming to give their time to our kids, and they related really well because their age difference is not so much. I hope we can do this a lot."
                    heading="Fabulous workshops!"
                  />
                  <Card
                    key={1}
                    name="Susanna T."
                    iconImg={soosieImg}
                    from="Careers Advisor from Bonnyrigg High School"
                    content="I would highly recommend you all get Advance Careers into your schools, the impact they make is everlasting. They are engaging and they will work to fit things in for your students!"
                    heading="Everlasting impact"
                  />
                  <Card
                    key={3}
                    name="Year 10 Student"
                    iconImg={testLogo}
                    from="from Parramatta High School"
                    content="Advance Careers workshops are very helpful as they make you think outside the box and get you to start thinking about your career."
                    heading="Helping you think outside the box"
                  />
                </Carousel>
              </Box>
              <motion.div 
                style={{
                  display: "flex",
                  flex: 1,
                  flexDirection: "column",
                  gap: "20px"
                }}
                initial={{ y: 30, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.2, type: "spring", stiffness: 140, damping: 12 }}
              >
                <Box sx={{ fontWeight: 600, fontSize: "24px", lineHeight: "100%" }}>
                  Hear What Others <br/>Have to Say About Advance Careers
                </Box>
                <Box sx={{ fontSize: "16px" }}>
                  At Advance Careers, we empower young Australians with the tools and guidance they need to succeed. Hear from students and educators who have experienced the impact firsthand.
                </Box>
              </motion.div>
            </Box>
          )}
        </motion.div>

        {/* Icons Section */}
        <Box sx= {{
          width: "100%",
          display: "flex",
          gap: "16px",
          justifyContent: "center",
          mt: "86px",
          mb: "64px",
          flexWrap: "wrap"
        }}>
          <IconContainer imgSrc={climbImg} delay={0.1}>
            <Box sx={{ display: "flex", gap: "3px", alignItems: "center" }}>
              <CountUp enableScrollSpy scrollSpyOnce scrollSpyDelay={200} end={2000} /> 
              students reached
            </Box>
          </IconContainer>
          <IconContainer imgSrc={usersImg} delay={0.2}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              Team of 
              <Box sx={{ mx: "3px" }}>
                <CountUp enableScrollSpy scrollSpyOnce scrollSpyDelay={200} end={30} /> 
              </Box>
              Uni Students
            </Box>
          </IconContainer>
          <IconContainer imgSrc={australiaImg} delay={0.15}>
            <Box sx={{ display: "flex", gap: "3px", alignItems: "center" }}>
              <CountUp enableScrollSpy scrollSpyOnce scrollSpyDelay={200} end={10} /> 
              schools across Australia
            </Box>
          </IconContainer>
        </Box>
      </Box>
    </Box>
  );
}

