import { motion } from 'framer-motion';
import { Box } from '@mui/material';
import { ReactComponent as ClockImg } from '../assets/misc_img/clock-solid.svg';
import { ReactComponent as StudentImg } from '../assets/misc_img/graduation-cap-duotone-solid.svg';
import { ReactComponent as StairsImg } from '../assets/misc_img/stairs-solid.svg';
import { ReactComponent as PinImg } from '../assets/misc_img/map-pin-solid.svg';

const Feature = ({ Icon, text }) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "6px",
        alignItems: "center",
        flex: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          backgroundColor: "#3b3b3bEE",
          borderRadius: "5px",
          height: "20px",
          width: "20px",
          alignItems: "center",
          justifyContent: "center",
          flexShrink: 0,
        }}
      >
        <Icon height="13px" fill="white" />
      </Box>
      <Box
        sx={{
          fontSize: { xs: "8px", sm: "11px" },
          fontWeight: 500,
          color: "#3b3b3bee",
          lineHeight: 1,
        }}
      >
        {text}
      </Box>
    </Box>
  );
};

export default function ProgramCard({
  imgUrl,
  title,
  desc,
  time,
  students,
  grade,
  location,
}) {
  return (
    <Box
      component={motion.div}
      sx={{
        display: "flex",
        alignItems: "center",
        borderRadius: "16px",
        backgroundColor: "white",
        boxShadow: "rgba(149, 157, 165, 0.025) 0px 1px 6px 6px",
        width: "100%",
        height: { xs: "180px", sm: "220px" },
        cursor: "pointer",
      }}
      transition={{ duration: 0.6, type: "spring", bounce: 0.5 }}
      whileHover={{
        translateY: -5,
        scale: 1.005,
        transition: { duration: 0.2 },
      }}
    >
      {/* Left Image */}
      <Box
        sx={{
          flex: 5,
          p: { xs: "8px", sm: "18px" },
          height: "100%",
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${imgUrl})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            borderRadius: "16px",
            width: "100%",
            height: "100%",
          }}
        />
      </Box>

      {/* Vertical Divider */}
      <Box
        sx={{
          height: "calc(100% - 24px)",
          width: "1px",
          backgroundColor: "#E4E1E199",
        }}
      />

      {/* Right Content */}
      <Box
        sx={{
          flex: 11,
          display: "flex",
          flexDirection: "column",
          p: { xs: "8px", sm: "16px" },
        }}
      >
        <Box
          sx={{
            fontWeight: 600,
            color: "#3b3b3b",
            fontSize: { xs: "14px", sm: "18px" },
          }}
        >
          {title}
        </Box>
        <Box
          sx={{
            color: "#3b3b3b",
            fontSize: { xs: "8px", sm: "11px" },
            mt: { xs: "4px", sm: "6px" },
            flexGrow: 1,
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 3,
            WebkitBoxOrient: "vertical",
          }}
        >
          {desc}
        </Box>
        {/* First row of features */}
        <Box
          sx={{
            display: "flex",
            gap: { xs: "6px", sm: "8px" },
            mt: { xs: "8px", sm: "16px" },
            width: "80%",
          }}
        >
          <Feature Icon={ClockImg} text={time} />
          <Feature Icon={StudentImg} text={students} />
        </Box>
        {/* Second row of features */}
        <Box
          sx={{
            display: "flex",
            gap: { xs: "6px", sm: "8px" },
            mt: { xs: "8px", sm: "12px" },
            width: "80%",
          }}
        >
          <Feature Icon={StairsImg} text={grade} />
          <Feature Icon={PinImg} text={location} />
        </Box>
      </Box>
    </Box>
  );
}

