import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Box, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import GradientButton from '../components/GradientButton';
import Footer from '../components/Footer';
import bgImg from '../../assets/bg_img/3.png';
import subjectExpertImg from '../assets/programs/homebush_subject_expert/preview.jpg';
import careerPanelImg from '../assets/programs/baulkham_career_panel/preview.jpg';
import studySkillsImg from '../assets/programs/paramatta_study_skills/preview.jpg';
import ProgramCard from './ProgramCard';
import Popup from '../../global_components/Animated/Popup';
import ProgramPopup from './ProgramPopup';

export default function Programs() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState(null);

  const handleProgramClick = (program) => {
    setSelectedProgram(program);
    setOpen(true);
  };

  return (
    <>
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundImage: `url(${bgImg})`,
          backgroundSize: "100% auto",
          backgroundPosition: "0 -100%",
          px: 2,
        }}
      >
        <Box sx={{ width: { xs: "100%", sm: "1120px" }, mb: "10%", mx: "auto" }}>
          <motion.div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              color: "#3B3B3B",
              marginTop: "64px",
            }}
            initial={{ y: 36, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ type: "spring", stiffness: 140, damping: 12 }}
          >
            <Box sx={{ fontWeight: 600, color: theme.palette.primary.main }}>
              Our Programs
            </Box>
            <Box
              sx={{
                textAlign: "center",
                fontWeight: 700,
                fontSize: "36px",
              }}
            >
              Empowering Students for Tomorrow
            </Box>
            <Box
              sx={{
                textAlign: "center",
                marginTop: "15px",
                fontSize: "14px",
                width: "80%",
              }}
            >
              At Advance Careers, we deliver impactful workshops that help high
              school students gain clarity on study strategies, career choices,
              and life after school. With engaging sessions led by university
              students, we provide hands-on guidance to prepare them for a
              successful future.
            </Box>
            <Tooltip title="Still under construction!">
              <GradientButton sx={{ fontSize: "14px", marginTop: "20px" }}>
                Find out more
              </GradientButton>
            </Tooltip>
          </motion.div>
        </Box>
      </Box>
      <Box
        sx={{
          transform: { xs: "translateY(-70px)", sm: "translateY(-140px)" },
          maxWidth: "100%",
          overflowX: "hidden",
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
          px: 2,
        }}
      >
        <Box
          component={motion.div}
          sx={{
            width: { xs: "100%", sm: "1200px" },
            borderRadius: "30px",
            backgroundColor: "rgb(243, 247, 255)",
            padding: { xs: "18px", sm: "36px" },
            gap: "32px",
            display: "flex",
            flexDirection: "column",
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.25, duration: 0.15 }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: "32px",
              width: "100%",
            }}
          >
            <Box
              onClick={() => handleProgramClick("Career Insights Panel")}
              sx={{ width: { xs: "100%", sm: "50%" }, cursor: "pointer" }}
            >
              <ProgramCard
                imgUrl={careerPanelImg}
                title="Career Insights Panel"
                desc="A panel of 4-5 university students from various degrees. Moderated discussions in an assembly format, with networking opportunities and a Q&A for student engagement."
                time="1 - 1.5 hours"
                students="100 - 300 Students"
                grade="Years 10 - 12"
                location="Auditorium/Hall"
              />
            </Box>
            <Box
              onClick={() => handleProgramClick("Study Skills Workshop")}
              sx={{ width: { xs: "100%", sm: "50%" }, cursor: "pointer" }}
            >
              <ProgramCard
                imgUrl={studySkillsImg}
                title="Study Skills Workshop"
                desc="Presented by university students who’ve succeeded through the same process, this workshop covers study techniques and strategies that helped us thrive in university and land jobs, offering insights and proven methods."
                time="40 - 60 minutes"
                students="20 - 30 Students"
                grade="Years 7+"
                location="Classroom"
              />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: "32px",
              width: "100%",
            }}
          >
            <Box
              onClick={() => handleProgramClick("Subject Expert Sessions")}
              sx={{ width: { xs: "100%", sm: "50%" }, cursor: "pointer" }}
            >
              <ProgramCard
                imgUrl={subjectExpertImg}
                title="Subject Expert Sessions"
                desc="We bring professionals or university students into classes to link studies with careers. For example, a university student and paralegal might visit a Legal Studies class to discuss legal career paths and opportunities."
                time="40 - 60 minutes"
                students="20 - 30 students"
                grade="Years 9 - 11"
                location="Classroom (Elective Class)"
              />
            </Box>
            <Box
              onClick={() => handleProgramClick("Career Exploration Day")}
              sx={{ width: { xs: "100%", sm: "50%" }, cursor: "pointer" }}
            >
              <ProgramCard
                imgUrl="https://media.istockphoto.com/id/1141465434/photo/attending-a-conference-to-develop-their-careers.jpg?s=612x612&w=0&k=20&c=UJ69AL1_TpUaGZLc7dFrJp0QL8SuNBurpMwBQJHCjUg="
                title="Career Exploration Day"
                desc="Students rotate between career representatives from fields like Law, Finance, and Engineering, getting insights into each career path and tips for success in those industries."
                time="1 - 1.5 hours"
                students="100 - 300 Students"
                grade="Years 10 - 12"
                location="Auditorium/Hall"
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Footer />
      <AnimatePresence>
        {open && (
          <Popup setOpen={setOpen}>
            <Box
              sx={{
                width: "clamp(40vw, 1200px, 90vw)",
                height: "clamp(40vh, 500px, 92vh)",
                borderRadius: "20px",
                backgroundColor: "#FFFFFF",
                marginTop: "50px",
                p: 2,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <ProgramPopup selectedProgram={selectedProgram} />
            </Box>
          </Popup>
        )}
      </AnimatePresence>
    </>
  );
}

