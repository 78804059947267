import { useState } from 'react';
import { Box, ButtonBase } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { ReactComponent as LogoIcon } from '../../assets/sidebar_icons/logo.svg';
import { ReactComponent as HomeIcon } from '../../assets/sidebar_icons/house-user-solid.svg'; 
import { ReactComponent as SuitcaseIcon } from '../../assets/sidebar_icons/suitcase-solid.svg';
import { ReactComponent as ChatIcon } from '../../assets/sidebar_icons/message-solid.svg';
import { ReactComponent as TreeIcon } from '../../assets/sidebar_icons/tree-solid.svg';

import StudySkillsOverview from './components/StudySkillsOverview';

// A simplified MenuButton for this popup environment
const MenuButton = ({ icon, name, onClick, selected }) => (
	<Box sx={{ width: "100%", padding: '0 20px', marginBottom: '5px'}}>
		<ButtonBase 
			variant="text"
			sx={{
				backgroundColor: selected ? "#F3F7FF" : "none",
				color: selected ? "#1B56BA" : "#A4A4A4",
				"& svg": {
					width: "24px",
					fill: selected ? "#1B56BA" : '#A4A4A4',
					transition: "all 250ms ease-in-out !important"
				},
				"&:hover": {
					backgroundColor: "#F3F7FF",		
					color: "#1B56BA",
					transition: "all 250ms ease-in-out",
					"& svg": {
						transition: "all 250ms ease-in-out",
						fill: "#1B56BA",
					}
				},
				width: '100%',
				borderRadius: '15px',
				padding: '10px 0 10px 28px',
				textAlign: 'left'
			}}
			onClick={onClick}
		>
			<Box sx={{ padding: "4px 10px", display: "flex", width: "100%", alignItems: "center" }}>
				{icon}<Box sx={{ marginLeft: "8px", fontWeight: 530, fontSize: "13px" }}>{name}</Box>
			</Box>
		</ButtonBase>
	</Box>
);

// Define different pages including icons
const programPages = {
  "Career Insights Panel": [
    { name: "Overview", icon: <ChatIcon /> },
    { name: "Schedule", icon: <TreeIcon /> },
    { name: "Panelists", icon: <SuitcaseIcon /> },
    { name: "FAQ", icon: <ChatIcon /> }
  ],
  "Study Skills Workshop": [
    { name: "Overview", icon: <TreeIcon /> },
    { name: "Techniques", icon: <SuitcaseIcon /> },
    { name: "Resources", icon: <ChatIcon /> },
    { name: "FAQ", icon: <TreeIcon /> }
  ],
  "Subject Expert Sessions": [
    { name: "Overview", icon: <ChatIcon /> },
    { name: "Experts", icon: <TreeIcon /> },
    { name: "Curriculum Links", icon: <SuitcaseIcon /> },
    { name: "FAQ", icon: <ChatIcon /> }
  ],
  "Career Exploration Day": [
    { name: "Overview", icon: <SuitcaseIcon /> },
    { name: "Rotations", icon: <ChatIcon /> },
    { name: "Industries", icon: <TreeIcon /> },
    { name: "FAQ", icon: <SuitcaseIcon /> }
  ],
};

// Content for each page using a nested switch
const PageContent = ({ program, page }) => {
  switch(program) {
    case "Career Insights Panel":
      switch(page) {
        case "Overview":
          return <Box>Career Insights Panel - Overview content goes here.</Box>;
        case "Schedule":
          return <Box>Career Insights Panel - Schedule details here.</Box>;
        case "Panelists":
          return <Box>Career Insights Panel - Panelists info here.</Box>;
        case "FAQ":
          return <Box>Career Insights Panel - FAQ content here.</Box>;
        default:
          return <Box>No page found for Career Insights Panel.</Box>;
      }

    case "Study Skills Workshop":
      switch(page) {
        case "Overview":
          return <StudySkillsOverview />
        case "Techniques":
          return <Box>Study Skills Workshop - Study Techniques here.</Box>;
        case "Resources":
          return <Box>Study Skills Workshop - Resources for study here.</Box>;
        case "FAQ":
          return <Box>Study Skills Workshop - FAQ content here.</Box>;
        default:
          return <Box>No page found for Study Skills Workshop.</Box>;
      }

    case "Subject Expert Sessions":
      switch(page) {
        case "Overview":
          return <Box>Subject Expert Sessions - Overview content goes here.</Box>;
        case "Experts":
          return <Box>Subject Expert Sessions - Details about the experts here.</Box>;
        case "Curriculum Links":
          return <Box>Subject Expert Sessions - How this links to the curriculum.</Box>;
        case "FAQ":
          return <Box>Subject Expert Sessions - FAQ content here.</Box>;
        default:
          return <Box>No page found for Subject Expert Sessions.</Box>;
      }

    case "Career Exploration Day":
      switch(page) {
        case "Overview":
          return <Box>Career Exploration Day - Overview content goes here.</Box>;
        case "Rotations":
          return <Box>Career Exploration Day - Rotation schedule here.</Box>;
        case "Industries":
          return <Box>Career Exploration Day - Industry details here.</Box>;
        case "FAQ":
          return <Box>Career Exploration Day - FAQ content here.</Box>;
        default:
          return <Box>No page found for Career Exploration Day.</Box>;
      }

    default:
      return <Box>Select a program and page from the sidebar.</Box>;
  }
};

export default function ProgramPopup({ selectedProgram }) {
  const pages = programPages[selectedProgram] || [];
  const [selectedPage, setSelectedPage] = useState(pages.length > 0 ? pages[0].name : "");

  return (
    <Box sx={{
      width: "100%",
      height: "100%",
      display: "flex"
    }}>
      {/* Sidebar */}
      <Box sx={{
        width: "300px",
        minWidth: "300px",
        height: "100%",
        backgroundColor: "white",
        borderRight: "1px solid #E8E8E8",
        display: "flex",
        flexDirection: "column",
      }}>
        <Box sx={{ 
          display: "flex", 
          flexDirection: "column", 
          justifyContent: "center",
          height: "100%",
          marginBottom: "20px"
        }}>
          <Box sx={{ 
            marginTop: "16px", 
            fontWeight: 600, 
            fontSize: "18px", 
            marginLeft: "28px", 
            marginBottom: "28px",
            color: "#3B3B3B" 
          }}>
            {selectedProgram}
          </Box>
          {pages.map((pageObj) => (
            <MenuButton 
              key={pageObj.name}
              icon={pageObj.icon} 
              name={pageObj.name}
              selected={selectedPage === pageObj.name}
              onClick={()=>setSelectedPage(pageObj.name)} 
            />
          ))}
        </Box>
      </Box>

      {/* Content Area */}
      <Box sx={{
        flex: 1,
        position: "relative",
        padding: "20px"
      }}>
        <AnimatePresence mode="wait">
          <motion.div
            key={selectedPage}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            style={{ position: "absolute", width: "100%", height: "100%" }}
          >
            <PageContent program={selectedProgram} page={selectedPage}/>
          </motion.div>
        </AnimatePresence>
      </Box>
    </Box>
  );
}

