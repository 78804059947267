import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import Graphic from './Graphic';
import FloatingDesc from '../../components/FloatingDesc';
import GradientButton from '../../components/GradientButton';
import bg1Img from '../../../assets/bg_img/1.png';
import "../../components/animations.css";

export default function FoundersAddress() {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        backgroundImage: `url(${bg1Img})`,
        backgroundPosition: "0 100%",
        backgroundRepeat: "no-repeat",
        width: "100vw",
        height: "95vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        px: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          width: { xs: "100%", sm: "1125px" },
          gap: { xs: 4, sm: "64px" },
          alignItems: "center",
        }}
      >
        {/* Text Content */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: { xs: "center", sm: "flex-start" },
            justifyContent: "center",
            color: "#3B3B3B",
            textAlign: { xs: "center", sm: "left" },
            mb: { xs: 2, sm: 0 },
            width: { xs: "100%", sm: "50%" }
          }}
        >
          <motion.div
            style={{ fontSize: "36px", fontWeight: 700 }}
            initial={{ y: 36, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ type: "spring", stiffness: 140, damping: 12 }}
          >
            Founders' <span style={{ color: theme.palette.primary.main }}>Foreword</span>
          </motion.div>
          <motion.div
            style={{ fontSize: "14px", marginTop: "24px" }}
            initial={{ y: 36, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.1, type: "spring", stiffness: 140, damping: 12 }}
          >
            Advance Careers was founded to address the challenges faced by Australian youth struggling to navigate their post‐high school life and career paths. Drawing on our own past experiences, we strive to provide a central hub of information to facilitate their journey.
          </motion.div>
          <motion.div
            style={{ fontSize: "14px", marginTop: "12px" }}
            initial={{ y: 36, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.1, type: "spring", stiffness: 140, damping: 12 }}
          >
            We firmly believe in nurturing today's youth as tomorrow's leaders by equipping them with essential skills and guidance, fostering qualities such as hard work, compassion, empathy, and curiosity.
          </motion.div>
          {/* Render the Contact us button for desktop only */}
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <motion.div
              initial={{ y: 36, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.2, type: "spring", stiffness: 140, damping: 12 }}
            >
              <GradientButton
                boxShadow
                sx={{ width: "200px", marginTop: "36px" }}
                clickHandler={() => navigate("/contact")}
              >
                Contact us
              </GradientButton>
            </motion.div>
          </Box>
        </Box>
        {/* Graphic */}
        <Box sx={{ width: { xs: "100%", sm: "50%" } }}>
          <Graphic mobileOffset={-16}/>
        </Box>
        {/* Render the Contact us button for mobile only below the graphic */}
        <Box sx={{ display: { xs: "block", sm: "none" }, mt: 2 }}>
          <GradientButton
            boxShadow
            sx={{ width: "200px" }}
            clickHandler={() => navigate("/contact")}
          >
            Contact us
          </GradientButton>
        </Box>
      </Box>
    </Box>
  );
}

