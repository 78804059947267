import { motion } from 'framer-motion';
import { Box } from '@mui/material';

export default function Card({
  name,
  missionStatement,
  role,
  img,
  portfolio,
  cardIcon,
  delay = 0,
  onClick,
}) {
  const cardVariants = {
    hidden: { translateY: -50, opacity: 0 },
    visible: {
      translateY: 0,
      opacity: 1,
      transition: { delay, duration: 0.6, type: 'spring', bounce: 0.5 },
    },
    hover: {
      translateY: -5,
      transition: { duration: 0.2 },
    },
  };

  return (
    <motion.div
      onClick={onClick}
      style={{
        display: 'flex',
        flexDirection: 'column',
        minWidth: '280px',
        maxWidth: '280px',
        borderRadius: '24px',
        overflow: 'hidden',
        boxShadow: '0px 3px 20px rgba(0, 50, 75, 0.1)',
        marginTop: '24px',
        marginBottom: '64px',
        cursor: 'pointer',
      }}
      variants={cardVariants}
      initial="hidden"
      animate="visible"
      whileHover="hover"
    >
      <Box
        sx={{
          height: '280px',
          borderRadius: '24px',
          overflow: 'hidden',
          backgroundImage: `url(${img})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      />
      <Box
        sx={{
          height: '200px',
          padding: '24px',
          color: '#3B3B3B',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ fontWeight: 600, fontSize: '18px' }}>{name}</Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              fontWeight: 500,
              fontSize: '14px',
              gap: '6px',
            }}
          >
            {role}
          </Box>
          {cardIcon && (
            <Box
              sx={{
                borderRadius: '50%',
                width: '36px',
                height: '36px',
                backgroundColor: '#F8F8F8',
                fill: '#A4A4A4',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '2px',
                svg: {
                  width: '18px',
                  height: '18px',
                },
              }}
              dangerouslySetInnerHTML={{ __html: cardIcon }}
            />
          )}
          <Box
            sx={{
              fontSize: '13px',
              marginTop: '10px',
            }}
          >
            {missionStatement}
          </Box>
        </Box>
      </Box>
    </motion.div>
  );
}

