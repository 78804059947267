import { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Carousel from '../../../global_components/Animated/Carousel';
import Card from '../../components/Card';
import TextButton from '../../components/TextButton';
import Popup from '../../../global_components/Animated/Popup';
import TeamMembers from './TeamMembers.js';

export default function TeamSection() {
  const theme = useTheme();
  const teamCategories = Object.keys(TeamMembers);
  const [selectedTeam, setSelectedTeam] = useState(teamCategories[0] || "");
  const [open, setOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);

  const currentTeamMembers = TeamMembers[selectedTeam] || [];

  const handleCardClick = (member) => {
    setSelectedMember(member);
    setOpen(true);
  };

  return (
    <Box
      sx={{
        width: "100vw",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        color: "#3B3B3B",
        px: 2,
        py: 4,
      }}
    >
      <Box sx={{ fontWeight: 600, color: theme.palette.primary.main }}>
        Our Team
      </Box>
      <Box sx={{ fontWeight: 700, fontSize: "28px", mb: 2 }}>
        Meet Our Team
      </Box>
      <Box
        sx={{
          fontSize: "15px",
          textAlign: "center",
          width: { xs: "100%", sm: "860px" },
          mb: 2,
        }}
      >
        We're a team of selfless, hardworking university students, once in your shoes as high school students, united by a fervent passion to empower change and inspire the leaders of tomorrow.
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "24px",
          justifyContent: "center",
          mb: 2,
          overflowX: "auto",
          whiteSpace: "nowrap",
          px: 1,
        }}
      >
        {teamCategories.map((category) => (
          <TextButton
            key={category}
            onClick={() => setSelectedTeam(category)}
            selected={selectedTeam === category}
          >
            {category}
          </TextButton>
        ))}
      </Box>
      <Box
        key={selectedTeam}
        sx={{
          height: "100%",
          width: { xs: "100%", sm: "1200px" },
          position: "relative",
          mx: "auto",
        }}
      >
        <Carousel containerWidth={1200} minCardWidth={300} gap={24}>
          {currentTeamMembers.length > 0 ? (
            currentTeamMembers.map((member, index) => (
              <Box key={`${selectedTeam}-${index}`} sx={{ minWidth: "300px" }}>
                <Card
                  name={member.name}
                  missionStatement={member.missionStatement}
                  role={member.role}
                  img={member.img}
                  portfolio={member.portfolio}
                  cardIcon={member.cardIcon}
                  delay={index * 0.1}
                  onClick={() => handleCardClick(member)}
                />
              </Box>
            ))
          ) : (
            <Box
              sx={{
                width: "100%",
                height: "800px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              No team members to display.
            </Box>
          )}
        </Carousel>
      </Box>
      <AnimatePresence>
        {open && selectedMember && (
          <Popup setOpen={setOpen}>
            <Box
              sx={{
                width: "clamp(40vw, 600px, 90vw)",
                minHeight: "300px",
                borderRadius: "20px",
                backgroundColor: "#FFFFFF",
                p: 3,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "24px",
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  aspectRatio: "1 / 1",
                  borderRadius: "50%",
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src={selectedMember.img}
                  alt={selectedMember.name}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    transform: "scale(1.2)",
                    transformOrigin: "center",
                    transition: "transform 0.3s ease",
                  }}
                />
              </Box>
              <Box sx={{ flex: 2 }}>
                <Box sx={{ fontSize: "24px", fontWeight: 500 }}>
                  {selectedMember.name}
                </Box>
                <Box
                  sx={{
                    fontSize: "14px",
                    fontWeight: 600,
                    lineHeight: "14px",
                    opacity: 0.8,
                    mb: 1,
                  }}
                >
                  {selectedMember.experienceSummary}
                </Box>
                <Box component="ul" sx={{ listStyleType: "disc", pl: "1rem" }}>
                  {selectedMember.experiencePoints.map((point, index) => (
                    <Box
                      key={index}
                      component="li"
                      sx={{ fontSize: "14px", lineHeight: "18px", opacity: 0.8 }}
                    >
                      {point}
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Popup>
        )}
      </AnimatePresence>
    </Box>
  );
}

