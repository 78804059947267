// Carousel.js
import React, { useState, useRef } from 'react';
import { Box, Button } from '@mui/material';
import { ReactComponent as LeftArrow } from '../../assets/misc_icons/left-arrow.svg';
import { ReactComponent as RightArrow } from '../../assets/misc_icons/right-arrow.svg';

export default function Carousel({
  children,
  scrollAmount = 300,       // Amount to scroll on each button click
  containerWidth = 1200,     // Width of the visible carousel area
  minCardWidth = 300,        // Minimum width of each card
  gap = 16                   // Gap (in px) between cards
}) {
  const [active, setActive] = useState(false);
  const scrollContainerRef = useRef(null);

  // Count the number of children and compute total required width.
  const childCount = React.Children.count(children);
  const totalChildrenWidth = childCount * minCardWidth + (childCount - 1) * gap;

  // If there isn’t enough content to fill the carousel, center the items.
  const justifyContent = totalChildrenWidth < containerWidth ? 'center' : 'flex-start';

  // Scroll the container by a specified distance.
  const scrollBy = (distance) => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: distance,
        behavior: 'smooth',
      });
    }
  };

  return (
    <Box 
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      sx={{ position: 'relative', display: 'flex', alignItems: 'center', width: '100%' }}
    >
      {/* Arrow Buttons */}
      <Box
        sx={{ 
          position: 'absolute', 
          zIndex: 100,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '32px',
          pointerEvents: 'none'
        }}
      >
        <Button 
          variant="light-circular"
          sx={{ 
            pointerEvents: "auto",
            opacity: active ? 1 : 0,
            transform: active ? "translateX(0px)" : "translateX(20px)",
            transition: "all 200ms cubic-bezier(0.12, 0.83, 0.58, 1)"
          }}
          onClick={() => scrollBy(-scrollAmount)}
        >
          <LeftArrow />
        </Button>
        <Button 
          variant="light-circular"
          sx={{ 
            pointerEvents: "auto",
            opacity: active ? 1 : 0,
            transform: active ? "translateX(0px)" : "translateX(-20px)",
            transition: "all 200ms cubic-bezier(0.12, 0.83, 0.58, 1)"
          }}
          onClick={() => scrollBy(scrollAmount)}
        >
          <RightArrow />
        </Button>
      </Box>

      {/* Scrollable Container */}
      <Box 
        ref={scrollContainerRef}
        sx={{
          overflowX: 'auto',
          display: 'flex',
          flexDirection: 'row',    // Force horizontal layout.
          flexWrap: 'nowrap',      // Prevent wrapping to new lines.
          gap: `${gap}px`,
          scrollBehavior: 'smooth',
          width: containerWidth,
          justifyContent: justifyContent,
          padding: '0 40px'        // Optional: adds side padding so arrows don’t overlap content.
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

