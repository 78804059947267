import { Box } from '@mui/material';
import { motion } from 'framer-motion';
import ecllipseImg from '../../../assets/bg_img/ecllipse_small.png';
import FloatingDesc from '../../components/FloatingDesc';
import foundersImg from '../../assets/unsw_shoot/Founders.jpg';
import "../../components/animations.css";

/**
 * @param {number} mobileOffset - How many pixels to shift the background image vertically on mobile.
 *                              Negative means move image UP, positive means move image DOWN.
 * @param {object} sx - Optional additional sx styles
 */
export default function Graphic({ mobileOffset = 0, sx = {} }) {
  return (
    <motion.div 
      initial={{ scale: 0.97, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      transition={{ delay: 0.1, type: "spring", stiffness: 110, damping: 12 }}
      style={{ ...sx }}
    >
      <Box 
        sx={{
          // Slightly larger dimensions on desktop
          width: { xs: "300px", sm: "400px" },
          height: { xs: "350px", sm: "480px" },
          transform: { xs: "none", sm: "translateY(24px)" },
          position: "relative",
        }}
      >
        {/* Floating ellipse */}
        <Box
          sx={{
            position: "absolute",
            top: -38,
            left: -38,
            transform: "scale(50%)",
            animation: "float 3s ease-in-out infinite"
          }}
        >
          <img src={ecllipseImg} alt="decorative ellipse" />
        </Box>

        {/* Floating text bubble */}
        <FloatingDesc
          sx={{
            position: "absolute",
            right: -32,
            top: 32,
            zIndex: 10,
            animation: "float 3s ease-in-out infinite"
          }}
        >
          Meet our
          <br />
          founders!
        </FloatingDesc>

        {/* Main background image */}
        <Box
          sx={{
            position: "absolute",
            zIndex: 5,
            width: "100%",
            height: "100%",
            borderRadius: "20px",
            overflow: "hidden",
            backgroundImage: `url(${foundersImg})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: {
              xs: `center calc(50% + ${mobileOffset}px)`,
              sm: "center"
            },
          }}
        />
      </Box>
    </motion.div>
  );
}

