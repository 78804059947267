import mattImg from '../../../assets/head_shots/matt.jpg';
import ethanImg from '../../../assets/head_shots/ethan.jpeg';
import maggieImg from '../../../assets/head_shots/maggie.jpeg';

import harrisonImg from '../../../assets/head_shots/harry.jpg';
import akifImg from '../../../assets/head_shots/akif.jpg';
import callumImg from '../../../assets/head_shots/callum.jpeg';
import carolImg from '../../../assets/head_shots/carol.jpeg';
import hazelImg from '../../../assets/head_shots/hazel.jpg';
import jamieImg from '../../../assets/head_shots/jamie.jpeg';
import jessicaImg from '../../../assets/head_shots/jessica.png';
import jonathanImg from '../../../assets/head_shots/jono.png';
import jordonImg from '../../../assets/head_shots/jordon.jpeg';
import joselynImg from '../../../assets/head_shots/joselyn.png';
import michaelgImg from '../../../assets/head_shots/michaelg.jpg';
import mikhailImg from '../../../assets/head_shots/mikhail.jpg';
import reenaImg from '../../../assets/head_shots/reena.jpeg';
import pamelaImg from '../../../assets/head_shots/pamela.jpeg';
import victorImg from '../../../assets/head_shots/victor.jpeg';
import winstonImg from '../../../assets/head_shots/winston.jpeg';


const TeamMembers = {
  Directors: [
    {
      name: "Matt Ng",
      missionStatement:
        "I see the potential within all people, and am devoted to fostering the next generation of young Australians",
      role: "Co-Founder, Director | All",
      experienceSummary:
        "Final year Commerce & Law student at the University of New South Wales",
      experiencePoints: [
        "Tax & Legal Consultant @ PwC",
        "Orientation Week Coordinator @ UNSW",
      ],
      img: mattImg,
    },
    {
      name: "Maggie Du",
      missionStatement:
        "I empower the next generation of legal professionals and leaders through mentorship, education, and service.",
      role: "Co-Founder, Director | Marketing",
      experienceSummary:
        "Penultimate year law and commerce student at the University of New South Wales, working as a paralegal",
      experiencePoints: [
        "HSC State Rank in Legal Studies",
        "Law Exchange @ the University of California, Berkeley",
        "Paralegal @ HY Solicitors (Personal Injury)",
        "Paralegal @ Mills Oakley (Insurance Litigation)",
      ],
      img: maggieImg,
    },
    {
      name: "Ethan Han",
      missionStatement:
        "I'm a passionate builder at heart, with a mission to bring ideas to life through the magic of software and technology.",
      role: "Co-Founder, Director | Products",
      experienceSummary:
        "4th year Computer Science & Finance student at the University of Sydney",
      experiencePoints: [
        "Teaching Assistant @ University of Sydney",
        "AI & Machine Learning @ CSIRO",
        "Software Engineer Intern @ TikTok",
        "Software Engineer Intern @ Canva",
        "Software Engineer Intern @ Qantas",
      ],
      img: ethanImg,
    },
  ],
  Executives: [
    {
      name: "Jonathan Yi",
      missionStatement:
        "Passionate about rural medicine, I am committed to learning how to make healthcare accessible in underserved communities.",
      role: "Content Manager | Products",
      experienceSummary:
        "Final year rural medical student at the University of New England currently on placement in Newcastle.",
      experiencePoints: [
        "Final year medical student at the University of New England",
        "On placement in Newcastle",
      ],
      img: jonathanImg,
    },
    {
      name: "Joselyn Singh",
      missionStatement:
        "I harness creative marketing strategies to empower youth and drive positive social impact.",
      role: "Marketing Manager | Marketing",
      experienceSummary:
        "B. Commerce/Science student at UNSW with a passion for marketing and a proven track record in executing impactful campaigns.",
      experiencePoints: [
        "Head of Marketing at Advance Careers",
        "Led social media and digital campaigns to boost engagement",
        "Organized educational workshops for high school students",
      ],
      img: joselynImg,
    },
    {
      name: "Michael Girgis",
      missionStatement:
        "Eager to leverage my business acumen and digital expertise to drive impactful marketing initiatives.",
      role: "Outreach Manager | Products",
      experienceSummary:
        "Final year business administration student at Macquarie University with experience in sales and freelance social media.",
      experiencePoints: [
        "Sales Agent at NU Marketing and Sales",
        "Freelance Social Media Specialist",
      ],
      img: michaelgImg,
    },
    {
      name: "Reena Chen",
      missionStatement:
        "I strive to merge legal insight with practical experience to foster fair and effective outcomes.",
      role: "Workshops Manager | Products",
      experienceSummary:
        "BCom/LLB student at UNSW with hands-on legal experience from several prestigious firms.",
      experiencePoints: [
        "Summer Clerk at Norton Rose Fulbright",
        "Paralegal at Colquhoun & Colquhoun Solicitors",
        "Legal Intern with McDonald’s legal team",
        "Tax & Legal Vacationer at Deloitte",
      ],
      img: reenaImg,
    },
    {
      name: "Winston Li",
      missionStatement:
        "I focus on identifying and nurturing talent to build dynamic teams and drive organizational success.",
      role: "Curriculum Lead | Products",
      experienceSummary:
        "Experienced campus recruiter at Gartner with a solid background in end-to-end talent acquisition and strategy.",
      experiencePoints: [
        "University Recruiter – Australia Lead at Gartner",
        "Talent Acquisition Business Partner at GHD",
        "Campus Recruitment Specialist at Cochlear",
        "Early Careers Consultant at PwC Australia",
      ],
      img: winstonImg,
    },
  ],
  General: [
    {
      name: "Akif Kazi",
      missionStatement:
        "I am committed to empowering youth through public health and marketing initiatives that make a real difference.",
      role: "Training Lead | Products",
      experienceSummary:
        "Commerce/Science graduate with hands‐on roles spanning health promotion, marketing coordination, and educational facilitation.",
      experiencePoints: [
        "Project Officer at NSW Health, delivering vital youth programs",
        "National Marketing Coordinator at Property Industry Foundation",
        "Educational Facilitator at Elephant Ed",
        "Youth Worker at PCYC NSW supporting community initiatives",
      ],
      img: akifImg,
    },
    {
      name: "Jamie C.",
      missionStatement:
        "I focus on building vibrant student communities and crafting engaging experiences that empower my peers.",
      role: "Communications Consultant | Marketing",
      experienceSummary:
        "A dedicated student leader at Arc – UNSW Student Life with a strong background in event management and community engagement.",
      experiencePoints: [
        "Clubs Assistant Manager at Arc – UNSW Student Life",
        "Coordinated large-scale in-person training sessions",
        "Revamped community engagement events to boost participation",
      ],
      img: jamieImg,
    },
    {
      name: "Callum Li",
      missionStatement:
        "Aspiring to bridge finance and technology through practical experience and innovative thinking.",
      role: "General Volunteer",
      experienceSummary:
        "UNSW student gaining practical exposure in investment banking and private equity.",
      experiencePoints: [
        "Investment Banking Intern at Pottinger",
        "Private Equity Intern at Pemba Capital Partners",
        "Transaction Diligence Intern at EY",
      ],
      img: callumImg,
    },
    {
      name: "Carol Newman",
      missionStatement:
        "Passionate about combining research with practice to enhance healthcare education and patient outcomes.",
      role: "Facilitator | Workshops",
      experienceSummary:
        "UNSW Medicine student actively involved in research and educational roles.",
      experiencePoints: [
        "Educator at lululemon, inspiring active lifestyles",
        "Student Research Assistant at Liverpool Hospital",
        "Research Intern at Lowy Cancer Research Centre",
      ],
      img: carolImg,
    },
    {
      name: "Hazel Li",
      missionStatement:
        "Driven to apply economic insights and analytical rigor to solve real-world financial challenges.",
      role: "Marketing | Marketing",
      experienceSummary:
        "B. Commerce/Economics student from UNSW with diverse internship experiences in finance and economic consulting.",
      experiencePoints: [
        "Economics & Finance Intern at Reserve Bank of Australia",
        "Economics Intern at the Australian Energy Market Commission",
        "Research Assistant at UNSW School of Economics",
        "Audit & Assurance Vacationer at KPMG",
      ],
      img: hazelImg,
    },
    {
      name: "Jessica Yoon",
      missionStatement:
        "I blend analytical skills with creative problem-solving to drive impactful solutions.",
      role: "General Volunteer",
      experienceSummary:
        "B. Actuarial Studies/Commerce student at UNSW with hands-on experience in consulting and tutoring.",
      experiencePoints: [
        "Actuarial Consultant Intern at Deloitte",
        "Tutor at K Education & Consulting Centre",
      ],
      img: jessicaImg,
    },
    {
      name: "Jordon Zhong",
      missionStatement:
        "A driven entrepreneur and student leader, I work to transform education through innovative tutoring solutions.",
      role: "General Volunteer | Products",
      experienceSummary:
        "Senior at NYU Stern School of Business and founder of Elite Tuition, blending academic insight with entrepreneurial spirit.",
      experiencePoints: [
        "Founded Elite Tuition to drive educational innovation",
        "Experience in securitization and strategic finance",
        "Active in venture capital and consulting projects",
      ],
      img: jordonImg,
    },
    {
      name: "Harrison Andrews",
      missionStatement:
        "Dedicated to applying analytical insight and hands‐on experience to foster growth and inspire innovation.",
      role: "Marketing | Marketing",
      experienceSummary:
        "Bachelor of Economics and Science (Econometrics & Psychology) from UNSW with diverse experience in research, marketing and tutoring.",
      experiencePoints: [
        "Assistant R&D Consultant at InCorp Advisory Australia",
        "Marketing Officer at Advance Australia Careers",
        "Coach for GeckoSports, enhancing team performance",
        "Private Tutor with over 5 years’ experience",
      ],
      img: harrisonImg,
    },
    {
      name: "Victor Zhang",
      missionStatement:
        "I aim to fuse engineering innovation with strategic leadership to drive forward-thinking solutions.",
      role: "General Volunteer | Products",
      experienceSummary:
        "2024 USYD Engineering graduate with leadership experience in sponsorship and engineering projects.",
      experiencePoints: [
        "Sponsorship Director at Sydney Motorsport",
        "General Structures Engineer in academic projects",
        "Consultant Intern at Jacaranda Flame Consulting",
      ],
      img: victorImg,
    },
    {
      name: "Mikhail Savkin",
      missionStatement:
        "Dedicated to applying actuarial science to assess and manage risk with precision and insight.",
      role: "General Volunteer | Products",
      experienceSummary:
        "UNSW Actuarial Co-op Scholar with internship experience at IAG, TAL Australia, and Swiss Re.",
      experiencePoints: [
        "Actuarial Intern at IAG",
        "Actuarial Intern at TAL Australia",
        "Actuarial Intern at Swiss Re",
      ],
      img: mikhailImg,
    },
    {
      name: "Pamela Pang",
      missionStatement:
        "Detail-oriented and driven, I deliver client-focused legal and educational solutions with precision.",
      role: "General Volunteer | Marketing",
      experienceSummary:
        "Dual-degree student in Law and Computer Science at UNSW with diverse experience in legal research and tutoring.",
      experiencePoints: [
        "Paralegal at Longton Compensation Lawyers",
        "Marketing Officer at Advance Careers",
        "Private Tutor committed to academic excellence",
        "Paralegal Intern at MVP Legal",
      ],
      img: pamelaImg,
    },
  ],
};

export default TeamMembers;

