import React, { useState, useEffect } from 'react';
import { Box, IconButton } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { wrap } from '@popmotion/popcorn';

const Slideshow = ({ images, alt, height = '300px', interval = 3000 }) => {
  // State now holds both the image index and the swipe direction.
  const [[currentIndex, direction], setCurrentIndex] = useState([0, 0]);

  // Wrap the index to loop seamlessly.
  const imageIndex = wrap(0, images.length, currentIndex);

  // Function to change slides, accepting a swipe direction (1 for next, -1 for previous)
  const swipeToImage = (swipeDirection, e) => {
    if (e) e.stopPropagation();
    setCurrentIndex(([prevIndex]) => [prevIndex + swipeDirection, swipeDirection]);
  };

  // Auto-play: advance the slide every 'interval' ms.
  useEffect(() => {
    if (images.length > 1) {
      const timer = setInterval(() => {
        swipeToImage(1);
      }, interval);
      return () => clearInterval(timer);
    }
  }, [currentIndex, images.length, interval]);

  // Handle drag end events to detect swipe gestures.
  const handleDragEnd = (e, dragInfo) => {
    const draggedDistance = dragInfo.offset.x;
    const swipeThreshold = 50;
    if (draggedDistance > swipeThreshold) {
      swipeToImage(-1);
    } else if (draggedDistance < -swipeThreshold) {
      swipeToImage(1);
    }
  };

  // Animation variants modeled after your provided example.
  const sliderVariants = {
    incoming: (direction) => ({
      x: direction > 0 ? '100%' : '-100%',
      scale: 1.2,
      opacity: 0,
    }),
    active: {
      x: 0,
      scale: 1,
      opacity: 1,
    },
    exit: (direction) => ({
      x: direction > 0 ? '-100%' : '100%',
      scale: 1,
      opacity: 0.2,
    }),
  };

  const sliderTransition = {
    duration: 1,
    ease: [0.56, 0.03, 0.12, 1.04],
  };

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: height,
        overflow: 'hidden',
        mt: 2,
        mb: '30px',
      }}
    >
      <AnimatePresence initial={false} custom={direction}>
        <motion.img
          key={currentIndex}
          src={images[imageIndex]}
          alt={alt}
          custom={direction}
          variants={sliderVariants}
          initial="incoming"
          animate="active"
          exit="exit"
          transition={sliderTransition}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={handleDragEnd}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            borderRadius: '8px',
            boxShadow: '0px 2px 6px rgba(0,0,0,0.2)',
          }}
        />
      </AnimatePresence>
      {images.length > 1 && (
        <>
          <IconButton
            onClick={(e) => swipeToImage(-1, e)}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '10px',
              transform: 'translateY(-50%)',
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.9)' },
              zIndex: 2,
            }}
          >
            <ArrowBackIosIcon sx={{ transform: 'translateX(4px)', fontSize: '1.2rem' }} />
          </IconButton>
          <IconButton
            onClick={(e) => swipeToImage(1, e)}
            sx={{
              position: 'absolute',
              top: '50%',
              right: '10px',
              transform: 'translateY(-50%)',
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.9)' },
              zIndex: 2,
            }}
          >
            <ArrowForwardIosIcon sx={{ fontSize: '1.2rem'}} />
          </IconButton>
        </>
      )}
    </Box>
  );
};

export default Slideshow;

