import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";

const YouTubeFlexBox = ({ videoId }) => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundImage: "url('your-background-image.jpg')", // Replace with your image URL
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderRadius: "32px",
        overflow: "hidden",
        width: "100%",
        maxWidth: "800px", // Adjust as needed
        aspectRatio: "16 / 9", // Maintain video aspect ratio
      }}
    >
      <iframe
        style={{ width: "100%", height: "100%", border: "none" }}
        src={`https://www.youtube.com/embed/${videoId}`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </Box>
  );
};

YouTubeFlexBox.propTypes = {
  videoId: PropTypes.string.isRequired,
};

export default YouTubeFlexBox;
