import { Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import blobImg from '../../../assets/img/front_page_blob.png';
import GradientButton from '../../components/GradientButton';
import RotationWrapper from '../../components/RotationWrapper';

export default function Hero() {
  const navigate = useNavigate();

  const handleBookWorkshop = () => {
    navigate('/contact')
  }

  return (
    <RotationWrapper constrain={500} delay={800}>
      <Box
        sx={{
          width: "100vw",
          minHeight: { xs: "80vh", sm: "100vh" },
          pt: { xs: "0", sm: 0 },
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          position: "relative",
          overflowX: "hidden",
          px: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            width: { xs: "100%", sm: "1125px" },
          }}
        >
          {/* Visual Section (Video + bubbles) */}
          <Box
            sx={{
              order: { xs: 1, sm: 2 },
              width: { xs: "100%", sm: "50%" },
              display: "flex",
              flexDirection: "column",
              position: "relative",
              alignItems: "center",
              mb: { xs: 4, sm: 0 },
            }}
          >
            {/* Bubble 1 */}
            <Box
              component={motion.div}
              sx={{
                position: "absolute",
                top: { xs: "-64px", sm: "-60px" },
                left: { xs: "-64px", sm: "-65px" },
              }}
              initial={{ scale: 0.5, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{
                opacity: { delay: 0.4, type: "spring", stiffness: 200, damping: 28 },
                default: { delay: 0.4, type: "spring", stiffness: 500, damping: 16 },
              }}
            >
              <Box
                component="img"
                src={blobImg}
                alt="Blob decoration"
                sx={{ width: { xs: "280px", sm: "300px" } }}
              />
            </Box>
            {/* Bubble 2 */}
            <Box
              component={motion.div}
              sx={{
                position: "absolute",
                bottom: { xs: "-64px", sm: "-100px" },
                right: { xs: "20px", sm: "-60px" },
              }}
              initial={{ y: 20, scale: 0.9, opacity: 0 }}
              animate={{ y: 0, scale: 1, opacity: 1 }}
              transition={{
                opacity: { delay: 0.6, type: "spring", stiffness: 200, damping: 28 },
                default: { delay: 0.6, type: "spring", stiffness: 400, damping: 18 },
              }}
            >
              <Box
                component="img"
                src={blobImg}
                alt="Blob decoration"
                sx={{ width: { xs: "225px", sm: "450px" } }}
              />
            </Box>
            {/* Video */}
            <Box
              component={motion.div}
              sx={{
                width: "100%",
                position: "relative",
              }}
              initial={{ y: 46, scale: 0.9, opacity: 0 }}
              animate={{ y: 0, scale: 1, opacity: 1 }}
              transition={{
                opacity: { delay: 0.3, type: "spring", stiffness: 200, damping: 28 },
                default: { delay: 0.3, type: "spring", stiffness: 120, damping: 8 },
              }}
            >
              <Box
                sx={{
                  borderRadius: "24px",
                  overflow: "hidden",
                  width: "100%",
                  position: "relative",
                  paddingTop: "56.25%", // maintains 16:9 aspect ratio
                }}
              >
                <iframe
                  src="https://www.youtube.com/embed/yVVf9Mna2Is"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                  }}
                />
              </Box>
            </Box>
          </Box>
          {/* Text Section */}
          <Box
            sx={{
              order: { xs: 2, sm: 1 },
              width: { xs: "100%", sm: "50%" },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              pr: { sm: "60px" },
              gap: "24px",
              textAlign: { xs: "center", sm: "left" },
              alignItems: { xs: "center", sm: "flex-start" },
            }}
          >
            <Box
              component={motion.div}
              sx={{
                fontWeight: 600,
                fontSize: { xs: "28px", sm: "42px" },
                lineHeight: "125%",
              }}
              initial={{ y: 36, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ type: "spring", stiffness: 140, damping: 12 }}
            >
              Advancing the careers of young Australians
            </Box>
            <Box
              component={motion.div}
              sx={{
                fontWeight: 400,
                fontSize: { xs: "16px", sm: "20px" },
              }}
              initial={{ y: 36, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{
                delay: 0.1,
                type: "spring",
                stiffness: 140,
                damping: 12,
              }}
            >
              Empowering Education, Both Onsite and Online. Click to Connect!
            </Box>
            <Box
              component={motion.div}
              initial={{ y: 36, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{
                delay: 0.2,
                type: "spring",
                stiffness: 130,
                damping: 12,
              }}
            >
              <GradientButton
                boxShadow
                sx={{
                  width: "220px",
                  fontSize: { xs: "12px", sm: "16px" },
                }}
                clickHandler={() =>
                  window.open("https://forms.gle/rk1C5VFGnZanoQWH8", "_blank")
                }
              >
                We're recruiting Click here to join!
              </GradientButton>
            </Box>
            <Box
              component={motion.div}
              initial={{ y: 36, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{
                delay: 0.4, // slightly delayed for a staggered effect
                type: 'spring',
                stiffness: 130,
                damping: 12,
              }}
            >
              <Button
                variant="outlined"
                size="large"
                sx={{
                  width: '220px',
                  textTransform: 'none',
                  borderRadius: '16px',
                  fontWeight: 500,
                  fontSize: { xs: '12px', sm: '16px' },
                  padding: '10px 36px',
                  borderColor: '#348AC7',
                  color: '#348AC7',
                  '&:hover': {
                    backgroundColor: 'rgba(52, 138, 199, 0.1)',
                    borderColor: '#348AC7',
                  },
                }}
                onClick={handleBookWorkshop}
              >
                Book Workshop!
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </RotationWrapper>
  );
}

