import { useState } from 'react';
import { motion } from 'framer-motion';
import { Box, Tooltip, Snackbar } from '@mui/material';
import { MotionConfig } from 'framer-motion';
import { useTheme } from '@mui/material/styles';
import GradientButton from '../components/GradientButton';
import ContactForm from './components/ContactForm';
import Graphic from './components/Graphic';
import WaysToHelp from './components/WaysToHelp';
import HelpPopup from './components/HelpPopup/HelpPopup';
import Footer from '../components/Footer';
import bgImg from '../../assets/bg_img/3.png';

export default function Contact() {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [popupMsg, setPopupMsg] = useState(null);

  return (
    <MotionConfig viewport={{ once: true }}>
      <Snackbar
        open={popupMsg !== null}
        autoHideDuration={3000}
        onClose={(event, reason) => {
          if (reason !== "clickaway") {
            setPopupMsg(null);
          }
        }}
        message={popupMsg}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      />
      <HelpPopup open={open} setOpen={setOpen} />
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          backgroundImage: `url(${bgImg})`,
          backgroundSize: "100% auto",
          backgroundPosition: "0 -100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: { xs: "100%", sm: "1120px" },
            mx: "auto",
            pt: "86px",
            overflowX: "hidden",
            px: 2,
          }}
        >
          <Box>
            <motion.div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                color: "#3B3B3B",
                marginTop: "64px",
              }}
              initial={{ y: 36, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ type: "spring", stiffness: 140, damping: 12 }}
            >
              <Box sx={{ fontWeight: 600, color: theme.palette.primary.main }}>
                Contact Us
              </Box>
              <Box sx={{ fontWeight: 700, fontSize: "36px" }}>
                Get in touch!
              </Box>
              <Box sx={{ textAlign: "center", width: "80%", marginTop: "15px" }}>
                We invite you to be a part of Advance Career's mission in supporting Australia's youth. Whether you're an individual or an institution, there are myriad ways to get involved and make a tangible difference.
              </Box>
              <Tooltip title="Still under construction!">
                <GradientButton
                  sx={{ fontSize: "14px", marginTop: "20px" }}
                  // clickHandler={() => setOpen(true)}
                >
                  Find out how
                </GradientButton>
              </Tooltip>
            </motion.div>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                alignItems: "center",
                width: "100%",
                gap: "96px",
                marginY: "64px",
              }}
            >
              <Box sx={{ width: { xs: "100%", sm: "50%" } }}>
                <Graphic />
              </Box>
              <Box sx={{ width: { xs: "100%", sm: "50%" } }}>
                <ContactForm setPopupMsg={setPopupMsg} />
              </Box>
            </Box>
          </Box>
        </Box>
        <Footer />
      </Box>
    </MotionConfig>
  );
}

